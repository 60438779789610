@import "../../../variables";

.team-member-card.active-slide {
  display: none;
  @media (min-width: $tablet-size) {
    display: block;
    max-width: 55%;
    margin-left: 50px;
    position: relative;

    .team-member-name {
      font-size: 18px;
    }

    .team-member-position {
      font-size: 16px;
      color: $main-selected-color;
    }

    .team-member-img {
      width: 373px;
    }
    .active-member-info {
      display: block;
      width: 60%;
      .team-info-list {
        font-size: 12px;
        list-style-position: inside;
        margin: 20px 0;
      }
      .work-achievements {
        color: $main-selected-color;
        margin-bottom: 10px;
        font-size: 14px;
      }
      .team-achievements-list {
        list-style-type: none;
        font-size: 14px;
        margin-bottom: 20px;
        .text-selected {
          color: $main-selected-color;
          font-weight: 500;
        }
      }

      blockquote {
        color: $main-selected-color;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
      }
    }
    .team-member-more {
      display: none;
    }
  }
}
