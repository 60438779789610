@import "../../../variables";

.team-modal-container {
  width: 76vw;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(164, 122, 100, 0.3);
  padding: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  @media (min-width: $tablet-size) {
    width: 50vw;
  }

  .cross {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    @media (min-width: $tablet-size) {
      width: 25px;
      height: 25px;
    }
  }

  h2 {
    color: $main-selected-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 17.6px;
    @media (min-width: $tablet-size) {
      font-size: 22px;
    }
  }
  h3 {
    color: $benefit-title-text-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.8px;
    @media (min-width: $tablet-size) {
      font-size: 18px;
      margin-top: 5px;
    }
  }

  .team-info-list {
    margin-top: 20px;
    list-style-position: inside;
    text-align: left;
    color: $benefit-title-text-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.6px;
    @media (min-width: $tablet-size) {
      font-size: 14px;
    }
  }
  .work-achievements {
    margin: 20px 0;
    color: $main-selected-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.017px;
    text-align: left;
    @media (min-width: $tablet-size) {
      font-size: 18px;
    }
  }

  .team-achievements-list {
    list-style-type: none;
    text-align: left;
    color: $benefit-title-text-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.014px;
    .text-selected {
      color: $main-selected-color;
      font-weight: 500;
    }
    @media (min-width: $tablet-size) {
      font-size: 16px;
      font-weight: 400;
    }
  }

  blockquote {
    text-align: left;
    margin-top: 20px;
    color: $main-selected-color;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 17.6px;
    @media (min-width: $tablet-size) {
      font-size: 16px;
    }
  }
}
