@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@import "./variables";

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

section {
  padding-top: 95px;
  @media (min-width: $tablet-size) {
    padding-top: 120px;
  }
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.toggle-btn {
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  color: rgba(78, 78, 78, 0.39);
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
}
