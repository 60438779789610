@import "../../../variables";

.service-card {
  width: 70%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(164, 122, 100, 0.3);
  padding: 25px 18px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: relative;

  @media (min-width: $tablet-size) {
    width: 30%;
    &:nth-child(even) {
      & > img {
        left: auto;
        right: -10px;
      }
    }

    &:nth-child(2) {
      & > img {
        bottom: -60px;
        transform: scale(1.1);
      }
    }
  }

  & > h3 {
    color: $main-selected-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    @media (min-width: $tablet-size) {
      font-size: 16px;
    }
  }

  & > p {
    color: $main-text-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    @media (min-width: $tablet-size) {
      font-size: 14px;
    }
  }

  & > img {
    position: absolute;
    left: -30px;
    bottom: -50px;
    width: 95px;
    height: 95px;
    @media (min-width: $tablet-size) {
      left: -10px;
      bottom: -80px;
      width: 130px;
      height: 130px;
    }
  }
  & > ul {
    list-style-position: inside;
    padding-left: 10px;

    & > li {
      color: $main-text-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }
  }
}
