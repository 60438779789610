@import "../../../../_variables.scss";
@import "../../../../_mixins.scss";

.register-section {
  text-align: center;
  padding: 100px 0;

  .register-title {
    color: $main-text-color;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px;

    @media (min-width: $tablet-size) {
      font-size: 30px;
    }
  }

  .register-container {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 10px 40px 0px rgba(164, 122, 100, 0.3);
    padding: 35px 40px;
    display: inline-block;
    max-width: 70vw;
    @media (min-width: $tablet-size) {
      padding: 40px 70px;
    }
  }

  .register-form {
    @include flex-column-between;
    align-items: stretch;
    margin-top: 15px;

    & input:not([type="checkbox"]) {
      padding: 16px 20px;
      border: 1px solid #e1ebeb;
      background: rgba(255, 255, 255, 0.3);
      height: auto;
      line-height: normal;
      font-size: 12px;
      border-radius: 0;
      outline: none;
      @media (min-width: $tablet-size) {
        padding: 20px 45px;
        font-size: 18px;
      }

      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      &::placeholder {
        color: rgba(102, 116, 115, 0.56);
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (min-width: $tablet-size) {
          font-size: 18px;
        }
      }
    }
  }

  .phone-input {
    width: 100%;
  }

  .flag-dropdown {
    display: none;
  }

  .form-checkbox {
    margin-top: 15px;
    margin-bottom: 30px;
    color: rgba(102, 116, 115, 0.56);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    & > input {
      appearance: none;
      margin-right: 5px;
      width: 8px;
      height: 8px;
      background: #b8b8b8;
      border: 2px solid transparent;

      &:checked {
        border-color: #b8b8b8;
        background-color: $main-text-color;
      }
      @media (min-width: $tablet-size) {
        width: 14px;
        height: 14px;
      }
    }
    @media (min-width: $tablet-size) {
      font-size: 18px;
    }
  }

  .submit-btn {
    cursor: pointer;
    padding: 7px 17px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-radius: 5px;
    border-bottom: 2px solid $main-selected-color;
    background: $main-selected-color;
    border: none;
    width: 80%;

    &:disabled {
      background: #c1c1c1;
      cursor: not-allowed;
    }
    @media (min-width: $tablet-size) {
      font-size: 14px;
      padding: 12px 16px;
      width: 30%;
    }
  }

  .success-register {
    text-align: center;
    img {
      width: 58px;
      height: 58px;
      @media (min-width: $tablet-size) {
        width: 88px;
        height: 88px;
      }
    }

    h2 {
      margin-top: 10px;
      color: $main-text-color;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      @media (min-width: $tablet-size) {
        margin-top: 35px;
        font-size: 30px;
      }
    }
  }
}
