@import "../../../../variables";
@import "../../../../mixins";

body.open-modal {
  overflow-y: hidden;
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.256);
    z-index: 5;
    width: 100vw;
    height: 1000vh;
  }
}

.team-section {
  text-align: center;
  position: relative;

  @media (min-width: $tablet-size) {
    .team-slider-container {
      @include flex-center;
      justify-content: space-between;
      margin-top: 50px;
    }
  }
  .teamSwiper {
    margin-top: 60px;
    height: 500px;

    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;

    @media (min-width: $tablet-size) {
      height: 500px;
      margin: 0;
      width: 30%;
      padding: 60px 0;
      // padding-bottom: 55px;
    }
    .active-member-info {
      display: none;
    }

    .prev-button.team,
    .next-button.team {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 29px;
      height: 29px;
      z-index: 5;
    }
    .next-button.team {
      top: 5px;
      @media (min-width: $tablet-size) {
        top: 0;
      }
    }
    .prev-button.team {
      bottom: 15px;
      @media (min-width: $tablet-size) {
        bottom: 0;
      }
    }
  }
}
