@import "../../../../mixins";
@import "../../../../variables";

.benefits-list {
  @include flex-column-between;
  @media (min-width: $tablet-size) {
    flex-direction: row;
    margin-top: 50px;
  }
}

.slider {
  margin-top: 50px;
  padding: 0 10px;
}

.slider img {
  width: 80px;
  height: 100px;
  object-fit: contain;
  user-select: none;
}
