@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@mixin flex-column-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
