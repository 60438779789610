@import "../../../mixins";
@import "../../../variables";

.prices-list-item {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.7px;
  @include flex-between;
  position: relative;
  z-index: 2;
  @media (min-width: $tablet-size) {
    font-size: 16px;
    font-weight: 600;
    padding: 0 30px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
    @media (min-width: $tablet-size) {
      margin-bottom: 45px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    height: 2px;
    bottom: -5px;
  }
}
