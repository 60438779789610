@import "../../../variables";
@import "../../../mixins";

header {
  background-color: #fff;
  padding: 10px 40px;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  @media (min-width: $tablet-size) {
    padding: 20px 10px;
  }
  
  .container {
    @include flex-between;

    & > #menu {
      display: none;

      &:checked {
        & + .burger > span {
          &:nth-child(1) {
            opacity: 0;
          }
          &:nth-child(2) {
            top: 50%;
            transform: translate(-50%, 0%) rotate(45deg);
          }

          &:nth-child(3) {
            top: 50%;
            transform: translate(-50%, 0%) rotate(-45deg);
          }
        }
        & ~ .navbar-menu {
          transform: translateX(0);
        }
      }
    }

    .burger {
      width: 24px;
      height: 13px;
      position: relative;
      z-index: 4;
      @media (min-width: $tablet-size) {
        display: none;
      }

      & > span {
        width: 24px;
        height: 1px;
        background-color: $main-selected-color;
        transition: 0.5s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:nth-child(2) {
          top: calc(50% - 6px);
        }
        &:nth-child(3) {
          top: calc(50% + 6px);
        }
      }
    }

    .navbar-menu {
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      height: 70vh;
      width: 40%;
      padding: 130px 30px 90px;
      background: #fff;
      box-shadow: 0px 10px 40px 0px rgba(164, 122, 100, 0.3);
      transform: translateX(-100%);
      transition: transform 0.5s;
      @include flex-column-between;
      @media (min-width: $tablet-size) {
        transform: translate(0);
        position: relative;
        height: auto;
        width: auto;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        order: 1;
        flex-direction: row;
        @include flex-between;
        width: 80%;
      }

      & > a {
        color: $main-text-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        transition: 0.5s;

        &:hover {
          color: #a47a64;
        }
      }

      & > .contacts {
        @include flex-column-between;
        row-gap: 15px;

        & > a {
          color: $main-text-color;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          @include flex-start;

          &::before {
            margin-right: 5px;
          }

          &:nth-child(1)::before {
            content: url("../../../assets/images/logos/info/map-pin.svg");
          }
          &:nth-child(2) {
            text-decoration: underline;
            &::before {
              content: url("../../../assets/images/logos/info/phone.svg");
            }
          }
        }
      }

      & > .socials {
        @media (min-width: $tablet-size) {
          display: none;
        }
        & > a:first-child {
          margin-right: 15px;
        }
      }
    }
    .header-phone {
      @media (min-width: $tablet-size) {
        display: none;
      }
    }
  }
}
