@import "../../../variables";

.benefit-card-item {
  padding: 0 22px;
  text-align: center;
  margin-top: 33px;
  @media (min-width: $tablet-size) {
    width: 33%;
  }

  & > .benefit-card-title-wrapper {
    position: relative;

    & > h3 {
      color: $benefit-title-text-color;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      padding: 30px 0;
      @media (min-width: $tablet-size) {
        font-size: 18px;
      }
    }

    & > .benefit-card-number {
      color: $text-selected-color;
      font-size: 96px;
      font-style: italic;
      font-weight: 600;
      line-height: 70px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > p {
    margin-top: 33px;
    color: $benefit-text-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    @media (min-width: $tablet-size) {
      font-size: 14px;
    }
  }
}
