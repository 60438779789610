@import "../../../../mixins";
@import "../../../../variables";

.about-section {
  padding-bottom: 30px;

  .content {
    margin-top: 35px;
    @media (min-width: $tablet-size) {
      flex-direction: row-reverse;
      @include flex-between;
    }

    & > .img-container {
      & > img {
        max-width: 95%;
        border-radius: 5px;
        @media (min-width: $tablet-size) {
          max-width: 422px;
          height: 560px;
        }
      }
      text-align: center;
      position: relative;
      &::before {
        content: "";
        width: 202px;
        height: 192px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(164, 122, 100, 0.6) 0%,
          rgba(164, 122, 100, 0) 100%
        );
        filter: blur(50px);
        position: absolute;
        left: -20%;
        bottom: -25%;
        z-index: -1;
      }
    }

    .text {
      margin-top: 40px;
      padding: 0 20px;
      text-align: left;
      color: $main-text-color;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      overflow: hidden;
      transition: max-height 1s;
      max-height: 150px;

      &.full {
        max-height: 700px;
      }

      @media (min-width: $tablet-size) {
        font-size: 14px;
        width: 70%;
        max-height: 200px;
      }
    }
  }
}
