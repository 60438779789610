@import "../../../../variables";
@import "../../../../mixins";

body.webp-support .reviews-section .content {
  background-image: url("../../../../assets/images/backgrounds/reviews.webp");
  @media (min-width: $tablet-size) {
    background-image: url("../../../../assets/images/backgrounds/reviews-desktop.webp");
  }
}

.reviews-section {
  .content {
    margin-top: 40px;
    background-image: url("../../../../assets/images/backgrounds/reviews.png");
    padding: 95px 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media (min-width: $tablet-size) {
      background-image: url("../../../../assets/images/backgrounds/reviews-desktop.png");
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      transform: scale(0.8);
      transition: 1s;
    }

    .slider-arrows {
      display: flex;
      gap: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 5%;
      cursor: pointer;
    }

    .user-slide {
      background-color: #fff;
      width: 70%;
      margin: 0 auto;
      text-align: center;
      padding: 30px 25px 50px;
      border-radius: 6px;
      box-shadow: 11px 11px 27px 0px rgba(41, 125, 125, 0.12);
      @media (min-width: $tablet-size) {
        @include flex-between;
        gap: 40px;
      }
      .user-profile {
        @media (min-width: $tablet-size) {
          margin-top: 50px;
        }
      }
      .user-text {
        @media (min-width: $tablet-size) {
          text-align: left;
        }
      }

      .user-letter {
        color: rgba(255, 255, 255, 0.83);
        font-family: "Inter", sans-serif;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 86.4px;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        @media (min-width: $tablet-size) {
          font-size: 72px;
        }

        &::before {
          content: "";
          border-radius: 50%;
          background: #ac8878;
          width: 80px;
          height: 80px;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          @media (min-width: $tablet-size) {
            width: 115px;
            height: 115px;
          }
        }
      }
      .user-estimate {
        margin-top: 5px;
        @media (min-width: $tablet-size) {
          margin-top: 30px;
          width: 100px;
        }
      }
      .user-name {
        color: rgba(68, 50, 40, 0.71);
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
        @media (min-width: $tablet-size) {
          font-size: 16px;
        }
      }

      .user-review {
        margin-top: 20px;
        color: $main-text-color;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.4px;
        @media (min-width: $tablet-size) {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}
