@import "../../../../mixins";
@import "../../../../variables";

.services-section {
  text-align: center;
  .services-list {
    padding-top: 50px;
    @include flex-column-between;
    align-items: center;
    gap: 50px;
    overflow: hidden;
    transition: 1s;
    max-height: 550px;
    position: relative;
    @media (min-width: $tablet-size) {
      max-height: 150vh;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      gap: 100px;
      overflow: visible;
    }

    &.full {
      max-height: 1500px; //!TODO: fix this with dynamic height

      &.visible {
        overflow: visible;
      }

      &::after {
        opacity: 0;
      }
    }

    &::after {
      content: "";
      transition: opacity 1s;
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      height: 300px;
      bottom: 0;
      background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 70.2%);
      @media (min-width: $tablet-size) {
        background: transparent;
      }
    }
  }
  .toggle-btn.services {
    display: inline-block;
    padding: 0;
    font-size: 14px;
    @media (min-width: $tablet-size) {
      display: none;
    }

    &.open {
      margin-top: 50px;
    }
  }
  .toggle-btn-container {
    text-align: left;
  }
}
