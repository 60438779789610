@import "../../../_mixins.scss";
@import "../../../variables";

footer {
  margin-top: 50px;
  padding: 50px 30px;
  position: relative;
  @include flex-column-between;
  @media (min-width: $tablet-size) {
    @include flex-between;
    flex-direction: row;
    align-items: start;
  }
  & > .footer-logo {
    text-align: center;
  }
  .footer-menu {
    display: none;
    @media (min-width: $tablet-size) {
      @include flex-column-between;
      align-items: start;
      gap: 20px;
      & > a {
        color: $main-text-color;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
      }
    }
  }

  .footer-title {
    color: #222;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left;
  }
  .working-hours {
    text-align: left;
    margin-top: 20px;

    @media (min-width: $tablet-size) {
      width: 28%;
      margin-top: 0;
    }
    .schedule-container {
      .schedule-item {
        padding-left: 30px;
        margin-top: 10px;
        @include flex-between;
        color: $main-text-color;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 26.4px;
        position: relative;
        &::before {
          content: url("../../../assets/images/logos/info/clock.svg");
          position: absolute;
          left: 0;
          top: 3px;
        }
      }
    }
  }

  .footer-contacts {
    margin-top: 18px;
    @media (min-width: $tablet-size) {
      margin-top: 0;
    }

    .contact-item {
      display: block;
      color: $main-text-color;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.1px;
      margin-top: 7px;
      padding-left: 26px;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        top: 3px;
      }
      &.address::before {
        content: url("../../../assets/images/logos/info/map-pin.svg");
      }
      &.phone {
        text-decoration: underline;
        &::before {
          content: url("../../../assets/images/logos/info/phone.svg");
        }
      }
      &.email::before {
        content: url("../../../assets/images/logos/info/mail.svg");
      }
    }
  }

  .footer-socials {
    margin-top: 20px;
    & > a:not(:last-child) {
      margin-right: 16px;
    }

    @media (min-width: $tablet-size) {
      position: absolute;
      bottom: 20px;
      right: 50px;
    }
  }
}
