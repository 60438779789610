@import "../../../variables";

.team-member-card {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(164, 122, 100, 0.3);
  padding: 35px 24px 64px;
  max-width: 70vw;
  margin: 0 auto;
  text-align: left;
  position: relative;

  @media (min-width: $tablet-size) {
    max-width: 20vw;
  }

  .team-member-name {
    color: $main-selected-color;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.6px;
  }
  .team-member-position {
    width: 70%;
    color: $benefit-title-text-color;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.8px;
  }

  .team-member-more {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 7px 34px;
    background-color: $main-selected-color;
    border-radius: 5px;
    display: inline-block;
    margin-top: 30px;
  }

  .team-member-img {
    position: absolute;
    right: -10%;
    bottom: 0;
    width: 167px;
  }
}
