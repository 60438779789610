@import "../../../../variables";

.cases-section {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 202px;
    height: 192px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(164, 122, 100, 0.6) 0%,
      rgba(164, 122, 100, 0) 100%
    );
    filter: blur(79.5px);
  }
  &::before {
    bottom: 0;
    left: 0;
  }
  &::after {
    top: 0;
    right: 0;
  }
  .section-title {
    padding-top: 15px;
  }
  .content {
    .slider-arrows {
      display: flex;
      gap: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      z-index: 1;
      cursor: pointer;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      transform: scale(0.8);
      transition: 1.5s;
    }
    .case-slide {
      text-align: center;
      padding: 50px 0;
      .case-card {
        width: 251px;
        background-color: #fff;
        border-radius: 5px;
        text-align: center;
        padding: 10px 0 15px;
        display: inline-block;
        box-shadow: 0px 4px 40px 0px rgba(164, 122, 100, 0.3);

        h4 {
          text-transform: uppercase;
          color: $main-selected-color;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 17.6px;
          width: 75%;
          margin: 0 auto;
          padding: 10px 0;
        }

        img {
          width: 251px;
          height: 251px;
          margin-bottom: 15px;
        }
        p {
          color: $main-text-color;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.8px;
        }
      }
    }
  }
}
