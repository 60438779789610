@import "../../../../variables";
body.webp-support .hero-section {
  background-image: url("../../../../assets/images/backgrounds/hero-mobile.webp");
  @media (min-width: $tablet-size) {
    background-image: url("../../../../assets/images/backgrounds/hero.webp");
  }
}

.hero-section {
  margin-top: 90px;
  background-image: url("../../../../assets/images/backgrounds/hero-mobile.jpg");
  height: 330px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  @media (min-width: $tablet-size) {
    background-image: url("../../../../assets/images/backgrounds/hero.png");
    margin-top: 110px;
    height: 73vh;
    max-height: 650px;
  }
}

.hero-section::after {
  content: "Ваша посмішка потрібна близьким";
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 28px 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background: rgba(164, 122, 100, 0.41);
  @media (min-width: $tablet-size) {
    left: 0;
    top: 140px;
    bottom: auto;
    width: 40%;
    font-size: 48px;
    line-height: 68px;
    text-align: left;
    padding-left: 100px;
    font-weight: 400;
    background: linear-gradient(50deg, #a47a6485, transparent);
  }
}

.gift {
  position: absolute;
  bottom: 85px;
  right: 10px;
  @media (min-width: $tablet-size) {
    bottom: 10px;
    right: 50px;
  }
  img {
    width: 50px;
    @media (min-width: $tablet-size) {
      width: 116px;
      bottom: 55px;
      right: 75px;
    }
  }
}
