@import "../../../_variables.scss";

.section-title {
  text-align: center;
  text-transform: uppercase;
  color: $text-selected-color;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  display: inline-block;
  position: relative;
  @media (min-width: $tablet-size) {
    font-size: 46px;
  }

  &::after,
  &::before {
    content: "";
    height: 5px;
    width: 5px;
    display: inline-block;
    background-color: $text-selected-color;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    @media (min-width: $tablet-size) {
      height: 10px;
      width: 10px;
      bottom: -10px;
    }
  }
  &::before {
    @media (min-width: $tablet-size) {
      left: -10px;
    }
  }

  &-wrapper {
    position: relative;
    text-align: center;
    overflow-x: hidden;
    padding-bottom: 5px;
    @media (min-width: $tablet-size) {
      padding-bottom: 10px;
    }
  }

  &-line {
    &::after {
      content: "";
      height: 1px;
      width: 100vw;
      display: block;
      background-color: $text-selected-color;
      position: absolute;
      @media (min-width: $tablet-size) {
        height: 2px;
        width: 110vw;
        bottom: -5px;
      }
    }
    &.left::after {
      right: 0;
      @media (min-width: $tablet-size) {
        right: -10px;
      }
    }
    &.right::after {
      left: 0;
      @media (min-width: $tablet-size) {
        left: -10px;
      }
    }

    &-wrapper {
      display: inline-block;
      position: relative;
    }
  }
}
