@import "../../../../mixins";
@import "../../../../variables";

body.webp-support .prices-section .prices-list {
  background-image: url("../../../../assets/images/backgrounds/tooth-brushes.webp");
  @media (min-width: $tablet-size) {
    background-image: url("../../../../assets/images/backgrounds/tooth-brushes-desktop.webp");
  }
}

.prices-section {
  @media (min-width: $tablet-size) {
    & > .section-title-wrapper {
      padding-top: 10px;
    }
  }
  .prices-list {
    list-style-type: none;
    margin-top: 60px;
    background-image: url("../../../../assets/images/backgrounds/tooth-brushes.png");
    padding: 45px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
    z-index: 0;
    @media (min-width: $tablet-size) {
      background-image: url("../../../../assets/images/backgrounds/tooth-brushes-desktop.png");
      background-position: top;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      background: rgba(172, 136, 120, 0.43);
      left: 0;
      right: 0;
      top: 3px;
      bottom: 0;
      @media (min-width: $tablet-size) {
        top: 0;
      }
    }
  }
}
